
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


body {
  font-family: 'Quicksand', sans-serif; 
}


.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-checkbox,
.ant-radio,
.ant-tag,
.ant-table,
.ant-typography,
.ant-list,
.ant-dropdown-menu
.ant-tabs

.ant-layout

.ant-col
.ant-row
.ant-pagination-options-quick-jumper
.ant-pagination
.ant-menu-item
.App
.ant-layout 

{
  font-family: Quicksand, sans-serif !important;
}
.ant {
  font-family: "Quicksand" !important;
}
.antd-menu-item{
  font-weight:bold ;
}
.ant-menu-item-selected{
  background-color: #000000 !important;
  
}

.cust-badge.ant-badge .ant-badge-count{
   z-index: auto !important;
    min-width: 46px;
    height: 46px;
    color: #ffffff;
    font-weight: normal;
    font-size: 20px;
    line-height: 46px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 30px;
    box-shadow: 0 0 0 1px #ffffff;
    
}
 .remove-dropdown-icon.span.ant-transfer-list-header-dropdown {
  display: none;
}
.export-button{

  background: white;
  color: #1DB9DE;
}
.export-button1{
  background: #1DB9DE;
  color: white;
 
}
.activetabcolor .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1DB9DE;
  text-shadow: 0 0 0.25px currentcolor;
  border-bottom: 3px solid #1DB9DE;
  padding: 10px;
  
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated
{
  text-decoration: none;
}

.spinner-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.74);
  position:fixed;
  top:0;
  left:0;
  z-index: 9999;

}

.ant-checkbox.foo span.ant-checkbox-inner {
  background-color: hotpink;
  border-radius: 80%;
}
.my-custom-tag {
  font-size: 14px; /* Change font size */
  padding: 5px 10px; /* Adjust padding */
  margin: 5px; /* Set margin */
}

.dont-show .ant-select-selection-item {
  max-height: 40px;
  overflow: hidden;
}

.dont-show .ant-select-selector{
  max-height: 40px;
  overflow: hidden;

}

.ant-radio-button-wrapper-checked{
  color:black !important;
  border-color: #D0D5DD !important;
  background: #D0D5DD !important;
}
.ant-tabs-nav-wrap {
  justify-content: center !important;
  padding-top: 10px;
  margin-top: 25px;
  border-top: 1px solid #EAECF0;
  border-bottom:none ;
}
.ant-tabs-nav::before {
  border-bottom: none !important;
}
.ant-tabs-ink-bar{
  display: none;
}
.page-title{
  font-weight: 400;
  line-height: 30px;
  font-size: 24px;
}
.page-title-subtitle{
  font-weight: 700;
  line-height: 30px;
  font-size: 24px;
}
.page-header{
  font-weight: 700;
  line-height: 25px;
  font-size: 20px;
  
}
.page-header-table{
  font-weight: 400;
  line-height: 25px;
  font-size: 20px;
  
}

.tag-csss{
  font-size: 14px;
  line-height: 22px;
}

.colo-min{
  color:"#001529"!important
}

.colo-max{
  color:""
}

.square-switch.ant-switch {
  
  background-color: #4b81b3 !important; 
}

.table-footer {
  text-align: left;
  padding: 10px 0;
  background: #f9f9f9;
  border-top: 1px solid #e8e8e8;
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 /* background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif); */
    height: 500px;
    background-position: center;
    background-image: url("/public/Loginsideimage.png");
    /* background-image: url("/public/4003.gif"); */

    background-repeat:no-repeat!important;
    /* height: 400px;
    background-position: center;
    background-repeat:no-repeat!important; */
 }
 
 
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}



  